<template>
  <div class="skuBody">

    <div class="main-content">
      <template v-for="(item,index) in skuList">
        <div class="subTitle">
<!--          {{ item.skuName }}（<span>{{ item.need }}</span>）件-->
          {{ item.skuName }}
        </div>

        <div class="selectSku">
          <span>已选:</span>

          <template>
            <div v-for="(select,temp) in item.selectSkuList" :key="temp" @click="unSelect(index,temp)">
              <div :style="item.thisIndex === temp?'border-bottom: 1px solid #e78f8f;':''">
                <img :src="require('@/assets/images/goods/goodsDetail/arrow.png')" v-show="item.thisIndex === temp" alt=""
                     style="width: 0.32rem;">
                <label v-for="(unSepc,unTemp) in select.prop">
                  <span class="item" v-if=" !unSepc.specValId">{{ unSepc.specValName }}</span>
                  <van-badge :id="'selectSku'+temp+'_'+ unSepc.specValId + '_'+unSepc.random"
                             v-else>
                    <div class="item active">
                      {{ unSepc.specValName }}
                    </div>
                  </van-badge>
                </label>
              </div>
              <div class="item_product">已选择：<span>{{ selectedProduct(index, temp) }}</span></div>
            </div>

          </template>
        </div>
        <div class="skuList">
          <div
              v-for="(sku,temp) in item.specificationVos" :key="sku.id"
          >
            <span class="spec_text" v-if="temp === 0">规格：</span>
            <span class="spec_text" v-else>{{ sku.name }}：</span>

            <div v-for="(skuVal,tempVal) in sku.specificationValues"
                 :key="tempVal"
                 :id="'sku' + skuVal.id"
                 :class="{skuItem: true,active: isActive(index,temp, skuVal.id), disabed: false }"
                 @click="selectSku(skuVal, index,temp)"
            >
              {{ skuVal.name }}
            </div>

          </div>
        </div>

      </template>

      <div class="title">
        <div class="text">
          数量
        </div>

        <van-stepper button-size="20" v-model="number"/>
      </div>


<!--      <div class="count">-->
<!--        <div class="left">-->
<!--          共计包数量：<span>{{ number }}</span>-->
<!--        </div>-->

<!--        <div class="price">-->
<!--          共计商品金额：<span>¥&nbsp;{{ total | money }}</span>-->
<!--        </div>-->
<!--      </div>-->

    </div>

    <van-row class="buttonGroup" :gutter="14">
      <van-col :span="24">
        <van-button block type="primary" size="small" @click="onConfirm">
          确定
        </van-button>
      </van-col>
    </van-row>

    <global-web-socket uri="/mall/ws/info" @setload="setLoad"></global-web-socket>
  </div>
</template>

<script>
import {gsap} from 'gsap';
import {mapState} from 'vuex'
import GlobalWebSocket from '@/components/WebSocket/GlobalWebSocket'
import Vue from "vue";

//模拟数据 , 我们需要对它进行处理， 处理的地方在 initSkuData
export default {
  components: { GlobalWebSocket},
  name: "exchangeSkuCustom",

  data() {
    return {
      number: 1,
      skuList: [],
      selectSkuList: [], // 二维数组，存放规格（包括id 价格 名称等内容）
      productPack: [],//商品包
      load: false,//加载
      //选择的商品id
      choiceProductId:"",
      //选择的商品名称
      choiceProductName:"",
    }
  },
  props: {
    product: {
      type: Object,
      "default": {},
    },
  },
  watch: {
    product: {
      handler(val) {
        if (val.id) {
          this.product = val;
          this.querySku();
        }
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState(['deptSet']),
    // total() {
    //   // 这里还需要合计每个规格的价格吧， 懒得造数据了
    //   // 理论上直接 统计 selectSkuList 这个二维数组里面的数据就可以了
    //   let totalPrice = 0;
    //   this.skuList.forEach(sku => {
    //     sku.selectSkuList.forEach(selectSku => {
    //       totalPrice += selectSku.totalPrice;
    //     })
    //   })
    //   return this.number * totalPrice;
    // },
    userId() {
      return Vue.prototype.userId;
    }
  },

  methods: {
    querySku() {
      this.$http.get(`/product/h5_product/queryGoodsThatCanBeExchanged/${this.product.id}/${Vue.prototype.deptId}`).then(res => {
        this.initSkuData(res.data);
      })
    },
    /**
     * @summary 获取每个选中的数量， （右上角的那个红点点）
     * @returns 数量
     *
     * @summary 废弃了， 我搞错需求了
     */
    getTotal(index, select) {
      return this.selectSkuList[index].filter(e => e.skuId === select.skuId).length
    },
    isActive(index, temp, id) {
      let sku = this.skuList[index];
      return sku.selectSkuList[sku.thisIndex].prop[temp].specValId === id;
    },
    initSkuData(e) {
      //尺码属性选择
      let specArr = e.specificationVos.map((spec, index) => {
        //"未选"+
        let newSpec = Object.assign({}, spec);
        return {
          specValId: null,
          specValName: (index > 0 ? newSpec.name : "尺码"),
          specValSku: '',
          // num: this.deptSet.pdspec === 0 ? e.need : 1
        };
      });
      //多层嵌套数组分配不同内存地址 JSON.parse(JSON.stringify(specArr))
      let selectSkuList = null;
      selectSkuList = Array.from({length: 1}, () => (JSON.parse(JSON.stringify({
        id: '',
        price: 0,
        // num: e.need,
        totalPrice: 0,
        name: '',
        prop: specArr
      }))));

      e.selectSkuList = selectSkuList;
      e.thisIndex = 0;//当前操作索引
      e.specificationVos.forEach((spec) => {
        spec.specificationValues.forEach(specVal => {
          specVal.able = true;
        })
      })
      this.skuList = [e]
      this.$forceUpdate();
    },
    isAble(sku, temp, specVal) {
      // 深拷贝 避免被影响
      let copySelectSku = JSON.parse(JSON.stringify(sku.selectSkuList[sku.thisIndex]));
      copySelectSku.prop[temp].specValSku = specVal.sku;
      let flag = sku.listMxSubProduct.some(item => {
        // eslint-disable-next-line no-unused-vars
        let i = 0;
        copySelectSku.prop.forEach(select => {
          if (select.specValSku !== '' && item.prop.indexOf(select.specValSku) !== -1 && !item.isOutOfStock) {
            i++;
          } else if (select.specValSku === '') {
            i++;
          }
        })
        return i === copySelectSku.prop.length
      })
      return flag
    },
    unSelect(index, temp) {
      this.skuList[index].thisIndex = temp;
    },
    selectedProduct(index, temp) {
      let skuName = '';
      let sku = this.skuList[index];
      let listMxSubProduct = sku.listMxSubProduct;
      let selectSku = sku.selectSkuList[temp];
      let propSkuArr = [];

      let can = selectSku.prop.every(select => {
        propSkuArr.push(select.specValSku)
        return select.specValId
      });

      if (can) {
        listMxSubProduct.forEach(mxSubProduct => {
          if (mxSubProduct.prop === propSkuArr.join("_")) {
            skuName = mxSubProduct.subProduct.name;
            selectSku.name = skuName;
            selectSku.id = mxSubProduct.subProduct.id;
            this.choiceProductId = mxSubProduct.subProduct.id;
            this.choiceProductName = mxSubProduct.subProduct.name;
            return true;
          }
        })
      }
      this.skuList[index].selectSkuList[temp] = selectSku;
      return skuName;
    },
    selectSku(item, index, tempVal) {

      let thisIndex = this.skuList[index].thisIndex;
      let d = this.skuList[index].selectSkuList[thisIndex].prop[tempVal];
      //第一次选择和选择其他sku
      if (!d.specValId || d.specValId !== item.id) {
        d.random = Math.floor(Math.random() * 1e3)
        d.specValId = item.id;
        d.specValSku = item.sku;
        d.specValName = item.name;
        this.skuList[index].selectSkuList[thisIndex].prop[tempVal] = d
      } else {
        //选择当前sku
        d.specValId = null;
        d.specValSku = '';
        d.specValName = (tempVal > 0 ? this.skuList[index].specificationVos[tempVal].name : "尺码");
        this.skuList[index].selectSkuList[thisIndex].prop[tempVal] = d
      }

      let selectSku = this.skuList[index].selectSkuList[thisIndex];

      //判断库存是不是大于订单的数量
      let strSpecSku = "";
      selectSku.prop.forEach((map) => {
        strSpecSku += map.specValSku + "_";
      })

      strSpecSku = strSpecSku.slice(0, -1);
      let thisItem = null;
      let can = this.skuList[index].listMxSubProduct.every(item => {
        let res = true;
        if (item.prop.indexOf(strSpecSku) !== -1 && item.prop.length === strSpecSku.length) {//如果选完3个规格后，查看商品的库存是否大于订单的库存。
          //true-> 已选数量+selectSku.num  false-> +0
          // item.selectedNum += res ? selectSku.num : 0;
          thisItem = item;
        }
        return res;
      })

      if (!can) {
        this.$dialog.alert({
          message: thisItem.productSpecItem.name + '商品库存小于' + selectSku.num,
        }).then(() => {
          // on close
        });
        //选择当前sku
        d.specValId = null;
        d.specValSku = '';
        d.specValName = (tempVal > 0 ? this.skuList[index].specificationVos[tempVal].name : "尺码");
        this.skuList[index].selectSkuList[thisIndex].prop[tempVal] = d
        return false;
      }

      // 做一个动画
      this.doAni(d, thisIndex);

      //是否选择完所有sku
      can = selectSku.prop.every(select => {
        return null === select.specValId
      });

      //选完所有sku，当前索引是不是最大的索引 是就不增加 如果不是就+1在去选择尺码
      if (can && this.skuList[index].thisIndex < this.skuList[index].selectSkuList.length - 1) {
        this.skuList[index].thisIndex++;
      }

      this.$forceUpdate();
    },
    doAni(item, thisIndex) {

      this.$nextTick(() => {
        let formSkuItem = document.querySelector('#sku' + item.specValId);
        let targetSkuItem = document.querySelector('#selectSku' + thisIndex + "_" + item.specValId + '_' + item.random);
        let tween = gsap.timeline({delay: 0});
        tween.from('#selectSku' + thisIndex + "_" + item.specValId + '_' + item.random, {
          duration: 1,
          ease: 'power2',
          opacity: 0,
          y: formSkuItem.offsetTop - targetSkuItem.offsetTop,
          x: formSkuItem.offsetLeft - targetSkuItem.offsetLeft,
          padding: [10, 13]
          // height: formSkuItem.offsetHeight,
          // width: formSkuItem.offsetWidth
        }, '>')
      })
    },

    vaild() {
      /*for (let i = 0, len = this.selectSkuList.length; i < len; i++) {
        for (let n = 0, childLen = this.selectSkuList[i].length; n < childLen; n++) {
          let e = this.selectSkuList[i][n]
          if (!e || !e.skuId) {
            this.$toast('请选择完整规格');
            return false
          }
        }
      }*/
      let can = this.skuList.every(sku => {
        return sku.selectSkuList.every(select => {
          return select.prop.every(specVal => {
            return null != specVal.specValId;
          })
        })
      });


      if (!can) {
        this.$toast('请选择完整规格');
      }
      return can;
    },

    setLoad(msgEvent) {
      this.load = false;
      this.$toast.success(msgEvent.data);
    },

    onConfirm() {
      if (!this.vaild()) {
        return;
      }
      this.$emit('onSubmit', {type: 'CONFIRM', data: {choiceProductId:this.choiceProductId,choiceProductName:this.choiceProductName}}); // 在sku 中发起请求， 这里只是对数据封装
    }
  },

  mounted() {
    // this.initSkuData()
  }
}

</script>
<style lang="less" scoped>
.skuBody {
  padding-bottom: 70px;
}

.title {
  margin: 12px 0 15px 0;
  display: flex;
  justify-content: space-between;

  .text {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    line-height: 15px;

    span {
      color: #FF0000;
      font-weight: 500;
      font-size: 12px;
    }
  }

}

.subTitle {
  font-size: 12px;
  font-weight: 500;
  color: #000000;

  span {
    color: #f00;
    font-weight: 500;
  }
}

.selectSku {
  padding: 14px 0 19px 0;

  span {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    line-height: 12px;
  }

  .item {
    margin-left: 8px;
    background: #F2F2F2;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #999999;
    display: inline-block;
    padding: 3px 7px;
    transition: all .2s;

    &.active {
      background-color: #F9EDEB;
      color: #333;

      img {
        height: 9px;
        width: 9px;
        margin-right: 5px;
      }
    }
  }

  .item_product {
    font-size: 0.3rem;
    color: #ea3f3f;
  }
}

.skuList {
  margin-right: -15px;

  .skuItem {
    display: inline-block;
    background: #F2F2F2;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #212121;
    padding: 10px 13px;
    margin-right: 13px;
    margin-bottom: 12px;

    position: relative;

    &.active {
      background: #F9EDEB;
      border: 1px solid #D6191C;
      border-radius: 4px;
      padding: 9px 12px;
      color: #D6191C;
    }

    &.specialItem {
      &::after {
        position: absolute;
        bottom: -16px;
        transform: translateX(-50%);
        left: 50%;
        content: '';
        border-bottom: 11px solid #F9EDEB;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
      }
    }

    .tag {
      height: 12px;
      width: 27px;
      border: 1px solid;
      border-radius: 2px;
      line-height: 12px;
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 10px;
      position: absolute;
      right: -7px;
      top: -6px;

      &.outofstock {
        border-color: #ccc;
        background: #CCCCCC;
      }

      &.booking {
        background: #D61619;
        border-color: #D61619;
      }
    }
  }
}


.count {
  display: flex;
  justify-content: space-between;
  margin: 21px 0 0 0;

  .left {
    font-size: 10px;
    font-weight: 400;
    color: #333333;
    line-height: 10px;

    span {
      color: #D53A26;
    }
  }

  .price {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    line-height: 12px;

    span {
      font-weight: 500;
      color: #D53A26;

    }
  }
}

.buttonGroup {
  position: fixed;
  background-color: #fff;
  bottom: 0;
  left: 18px;
  right: 18px;
  padding-top: 14px;
  padding-bottom: 14px;
}
</style>
