<template>
  <div class="page-gray">
    <div class="group">
      <div class="body">
        <div class="checboxBody" v-for="(item,index) in goodsList">
          <div class="goodsItem">
            <div class="name van-multi-ellipsis--l2">
              <div>{{ item.subProductName }}</div>
            </div>
            <div class="goodsInfo">
              <div class="imgBox">
                <van-image
                    :src="item.pic"
                />
                <div class="state">售后申请中</div>
              </div>
              <div class="right">
                <div class="top">
                  <div class="price">
                    <span>¥</span>
                    <span>{{ item.price }}</span>
                  </div>
                </div>
                <div class="sku">
                  规格：{{ item.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ item.selectNum }}
                </div>
                <div class="tagList">
                  <div class="tag" v-show="item.saleProduct.deliveryMode ==='2'">配送到校</div>
                  <div class="tag" v-show="item.saleProduct.deliveryMode ==='1'">快递到家</div>
                </div>
                <div class="tips" v-show="item.saleProduct.deliverGoodsTemp === '1'">下单{{
                    item.saleProduct.day
                  }}天内发货：
                </div>
                <div class="tips" v-show="item.saleProduct.deliverGoodsTemp === '2'">
                  预计配送时间：{{ item.saleProduct.deliverGoodsContent }}
                </div>
                <div class="sku" @click="changeSku(item,index)">
                  商品更换为：
                </div>
                <div style="display: inline-block;font-size: 9px;color: rgb(0, 172, 253);"
                     v-if="null !== item.changeName"> {{ item.changeName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cell" @click="showReason = true">
        <div class="left">
          <span>*</span>
          换货原因
        </div>
        <div class="right">
          <div class="placeholder" v-if="!reasonId">请选择</div>
<!--          <div class="value" v-else>{{ getReasonText(reasonId) }}</div>-->
          <div class="value" v-else>{{ reasonId }}</div>
          <img src="./../../assets/images/order/submitOrder/arrow.png" alt="">
        </div>
      </div>
      <!--      <div class="cell" @click="changeSku">
              <div class="left">
                <span>*</span>
                换货商品
              </div>
              <div class="right">
                <div class="placeholder" v-if="!skuId">请选择</div>
                <div class="value" v-else>{{ skuName }}</div>
                <img src="./../../assets/images/order/submitOrder/arrow.png" alt="">
              </div>
            </div>-->


      <div class="cell textarea">
        <div class="left">
          换货备注和上传凭证
        </div>
        <div class="bottom">
          <van-field
              v-model="message"
              rows="1"
              class="input"
              autosize
              type="textarea"
              placeholder="填写备注，有助于厂家更好处理售后问题"
          />

          <div class="imgList">
            <van-uploader
                v-model="fileList"
                multiple
                preview-size="45"
                :max-count="4"
                :after-read="afterRead"
                :before-read="beforeRead"
            >
              <div class="imgItem add" style="height: 45px; width: 45px">
                <img style="height: 20px; width: 20px" src="../../assets/images/photo.png"/>
              </div>
            </van-uploader>

          </div>
        </div>
      </div>

      <div class="cell">
        <div class="left">
          <span>*</span>
          寄回单号
        </div>
        <div class="right">
          <van-field  v-model="sendBackOrderNumber" placeholder="快递单号" />
        </div>
      </div>

    </div>

    <div class="group">
      <div class="cell">
        <div class="left">
          返回方式
        </div>
        <div class="right">
          <div class="value">快递至厂家</div>
        </div>
      </div>
    </div>
    <!-- <div class="cell">
        <div class="left">
            退款金额：<span>￥128.00</span>
        </div>
        <div class="right">
            邮费退款：<span>￥8.00</span>
        </div>
    </div>

    <div class="cell">
        <div class="left">
            共计：退货&nbsp;<span>2</span>&nbsp;件&nbsp;&nbsp;&nbsp;&nbsp;退款&nbsp;<span>128</span>&nbsp;元
        </div>
        <div class="right">

        </div>
    </div> -->


    <div class="tips">
      温馨提示:仅限更换同款相同价格商品，如需申请换货联系客服处理。
    </div>


    <van-action-sheet v-model="showReason" title="退款原因" close-icon="close">
      <van-radio-group v-model="reason_text">
        <van-cell-group>
<!--          @click="reason_stack = item.value"-->
          <van-cell v-for="item in reasonList" :title="item.text" clickable @click="select(item)">
            <template #right-icon>
              <van-radio :name="item.text" checked-color="#FF553E"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-field v-if="textShow" v-model="thisText" placeholder="请输入备注"/>
      <div style="padding: 10px 18px 18px 23px;">
        <van-button color="#D53A26" type="primary" size="small" block round
                    @click="reasonId = reason_stack; showReason = false">提交
        </van-button>
      </div>
    </van-action-sheet>

    <div style="padding: 10px 18px; bottom: 0;width: 100vw;">
      <van-button type="primary" size="small" block @click="submit">提交</van-button>
    </div>
    <ExchangeGoods ref="sku"/>
  </div>
</template>

<script>
import SKUTYPE from "@/views/goods/components/skuType";
import ExchangeGoods from '@/components/goods/exchangeGoods';
import Vue from "vue";

export default {
  name: "CreateRefund",
  components: {
    ExchangeGoods,
  },
  data() {
    return {
      reason_stack: '',
      reason_text:'',
      fileList: [],
      voucher:[],
      //订单明细
      goodsList: [],
      //售后类型
      type: '',
      //售后快递单号
      sendBackOrderNumber:"",
      reasonId: '',
      showReason: false,
      textShow: false,
      thisText: "",
      skuName: '',
      skuId: '',
      message: '',
      reasonList: [{
        text: '多拍、错拍、不想要',
        value: 1
      }, {
        text: '协商一致退款',
        value: 2
      }, {
        text: '价格太贵了',
        value: 3
      }, {
        text: '发货慢',
        value: 4
      }, {
        text: '其他',
        value: 5
      }]
    }
  },
  watch: {
    thisText:{
      handler(val) {
        this.reason_stack = val
        this.$forceUpdate()
      },
      immediate: true,
    }
  },
  methods: {
    init() {
      this.goodsList = JSON.parse(localStorage.getItem('goodsList'));
      this.type = localStorage.getItem('type');
    },
    getReasonText(val) {
      if (val === '') {
        return {text: ''}
      }
      return this.reasonList.find(e => e.value == val).text;
    },

    changeSku(item) {
      let skuType = SKUTYPE.CUSTOM;
      this.$refs.sku.subProductId = item.subProductId;
      this.$refs.sku.showPop(({data}) => {
        // data 这是结果
        item.changeProductId = data.choiceProductId
        item.changeName = data.choiceProductName
      }, skuType)
    },
    beforeRead(file) {
      const isImgType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isImgType) {
        this.$toast("上传图片只能是 JPG或PNG 格式!");
        return false;
      }
      if (!isLt10M) {
        this.$toast("上传图片大小不能超过 10MB!");
        return false;
      }
      return true;
    },
    // 组件上传
    afterRead(file) {
      file.status = 'uploading';
      file.message = '上传中...';
      console.log(file.content); // 给到的是一个base64字符串
      let form = new FormData();

      form.append('file', file.file);
      this.$http.post(this.baseMallUrl + `/h5_mall/upload`, form,
          {headers:{"content-type":  "multipart/form-data"}}).then((res) => {
        if (res.data) {
          file.status = "done";
          file.message = "上传完成";
          this.voucher.push(res.data.url);
        } else {
          file.status = "failed";
          file.message = "上传失败";
          this.$toast(res.data);
        }
      });

    },
    submit() {
      if (!this.reasonId) {
        this.$toast('请选择原因');
        return;
      }

      let can = this.goodsList.every(item=> item.changeProductId);
      if (!can) {
        this.$toast('有商品没有选择规格');
        return;
      }
      //凭证
      let voucher = this.voucher.join(",");

      this.goodsList.forEach((item)=>{
        item.reason_stack = this.reason_stack;
      })

      this.$http.post(this.baseMallUrl + `/h5_mall/userAppliesForAfterSalesService`,
          {orderItems:this.goodsList,afterSalesParam:{type:this.type,voucher:voucher,sendBackOrderNumber:this.sendBackOrderNumber,deptId:Vue.prototype.deptId}}, {
            emulateJSON: true
          }).then(response => {
        response
        this.$toast.success('申请成功')
      })
    },
    select(item){
      this.textShow = item.value === 5;
      this.reason_text = item.text;
      this.reason_stack = this.textShow ? this.thisText : item.text;
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style lang="less" scoped>
.group {
  margin-bottom: 12px;
}


.cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  background-color: #fff;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  &.textarea {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 15px;
  }

  .left {
    padding: 15px 0;
  }

  .bottom {
    width: 100%;
    background: #F4F4F4;
    border-radius: 4px;
    padding: 17px;
    padding-bottom: 10px;

    .imgList {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;

      .imgItem {
        height: 41px;
        width: 41px;
        margin-right: 10px;
        margin-bottom: 10px;

        img {
          height: 41px;
          width: 41px;
        }

        &.add {
          display: flex;
          border: 1px dashed #999999;
          align-items: center;
          justify-content: center;

          img {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }

  .left, .right {
    font-size: 14px;
    color: #333333;

    span {
      color: #E01212;
    }


    .placeholder {
      color: #999;
      display: inline-block;
      vertical-align: middle;
    }

    .value {
      display: inline-block;
      vertical-align: middle;
    }

    img {
      width: 9px;
      height: 15px;
      display: inline-block;
      margin-left: 12px;
      vertical-align: middle;
    }
  }
}

.tips {
  font-size: 10px;
  color: #FF0000;
  line-height: 18px;
  margin-top: 12px;
  //padding: 0 18px;
}

::v-deep .van-action-sheet__close {
  top: 8px;
}

::v-deep .van-action-sheet__header {
  padding: 8px 0;
}

::v-deep .van-cell-group::after {
  display: none;
}

::v-deep .van-cell.input {
  background-color: transparent;
  padding: 0;
}

::v-deep .van-uploader__mask-message {
  display: none;
}

.body {
  background-color: #f4f4f4;
}


.body {
  background-color: #fff;
  border-radius: 13px 13px 0 0;
  padding: 0 31px;

  .checboxBody {
    display: flex;
    align-items: center;
  }

  .goodsItem {
    padding-bottom: 25px;
    flex: 1;

    .name {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 15px;
      padding: 13px 0;
    }

    .goodsInfo {
      display: flex;
      align-items: flex-start;
    }

    .van-image,
    .imgBox {
      width: 110px;
      min-width: 110px;
      height: 92px;
    }

    .imgBox {
      margin-right: 20px;
      position: relative;

      .state {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 0px 0px 5px 5px;
        text-align: center;
        line-height: 20px;
        color: #fff;
        font-size: 11px;
      }
    }

    .right {
      flex: 1;
      width: 0;
      font-size: 0;

      .top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .price {
          line-height: 15px;
          font-size: 12px;
          font-weight: bolder;
          color: #d53a26;

          span:last-child {
            margin-left: 2px;
            font-size: 15px;
          }
        }
      }

      .sku {
        background: #eeeeee;
        border-radius: 2px;
        padding: 5px 10px;
        line-height: 10px;
        font-size: 10px;
        color: #666666;
        display: inline-block;
        margin-top: 8px;
      }

      .tagList {
        font-size: 0;
        margin-top: 8px;

        .tag {
          display: inline-block;
          background: #ffffff;
          border: 1px solid #ff0000;
          border-radius: 3px;
          line-height: 10px;
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ff0000;

          padding: 2px 5px;
        }
      }

      .tips {
        line-height: 10px;
        font-size: 10px;
        color: #e7ac58;
        margin-top: 8px;
      }
    }
  }
}
</style>
